export default [
  {
    path: "/",
    name: "dashboard",
    component: () => import("@/views/Dashboard/ecommerce/Ecommerce.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users",
    name: "users",
    component: () => import("@/views/Users"),
    meta: {
      pageTitle: "Usuarios",
      breadcrumb: [
        {
          text: "Lista de Usuarios",
          active: true,
        },
      ],
    },
  },
  {
    path: "/roles-permissions",
    name: "roles-permissions",
    component: () => import("@/views/RolesPermissions"),
    meta: {
      pageTitle: "Roles y Permisos",
      breadcrumb: [
        {
          text: "Lista de Roles",
          active: true,
        },
      ],
    },
  },
  {
    path: "/users/users-students",
    name: "users-students",
    component: () => import("@/views/Users"),
    meta: {
      pageTitle: "Estudiantes",
      breadcrumb: [
        {
          text: "Lista de Estudiantes",
          active: true,
        },
      ],
    },
  },
  {
    path: "/users/users-representatives",
    name: "users-representatives",
    component: () => import("@/views/Users"),
    meta: {
      pageTitle: "Representantes",
      breadcrumb: [
        {
          text: "Lista de representantes",
          active: true,
        },
      ],
    },
  },

  {
    path: "/users/users-teachers",
    name: "users-teachers",
    component: () => import("@/views/Users"),
    meta: {
      pageTitle: "Profesores",
      breadcrumb: [
        {
          text: "Lista de profesores",
          active: true,
        },
      ],
    },
  },
  {
    path: "/users/users-cashier",
    name: "users-cashiers",
    component: () => import("@/views/Users"),
    meta: {
      pageTitle: "Cajeros",
      breadcrumb: [
        {
          text: "Lista de Cajeros",
          active: true,
        },
      ],
    },
  },
  {
    path: "/users/users-admins",
    name: "users-admins",
    component: () => import("@/views/Users"),
    meta: {
      pageTitle: "Administradores",
      breadcrumb: [
        {
          text: "Lista de administradores",
          active: true,
        },
      ],
    },
  },
  // Schools
  {
    path: "/schools",
    name: "schools",
    component: () => import("@/views/Schools"),
    meta: {
      pageTitle: "Colegios",
      breadcrumb: [
        {
          text: "Lista",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/:page?",
    name: "products",
    component: () => import("@/views/Products"),
    meta: {
      pageTitle: "Productos",
      breadcrumb: [
        {
          text: "Lista",
          active: true,
        },
      ],
    },
  },
  //   Category products
  {
    path: "/products/categories",
    name: "products-categories",
    component: () => import("@/views/Products/Categories"),
    meta: {
      pageTitle: "Categorias",
      breadcrumb: [
        {
          text: "Lista",
          active: true,
        },
      ],
    },
  },
  {
    path: "/transactions/:userId?",
    name: "transactions",
    component: () => import("@/views/Transactions/index"),
    meta: {
      pageTitle: "Transacciónes",
      breadcrumb: [
        {
          text: "Lista",
          active: true,
        },
      ],
    },
  },
  {
    path: "/e-commerce/shop",
    name: "apps-e-commerce-shop",
    component: () =>
      import("@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue"),
    meta: {
      contentRenderer: "sidebar-left-detached",
      contentClass: "ecommerce-application",
      pageTitle: "Tienda",
      breadcrumb: [
        {
          text: "Tienda",
        },
        {
          text: "Productos",
          active: true,
        },
      ],
    },
  },
  {
    path: "/e-commerce/shop/public",
    name: "apps-e-commerce-shop-public",
    component: () =>
      import("@/views/apps/e-commerce/e-commerce-shop/ECommerceShopSecond.vue"),
    meta: {
      contentRenderer: "sidebar-left-detached",
      contentClass: "ecommerce-application",
      pageTitle: "Tienda",
      breadcrumb: [
        {
          text: "Tienda",
        },
        {
          text: "Productos",
          active: true,
        },
      ],
    },
  },
  {
    path: "/users/create/:typeUser",
    name: "user-create",
    component: () => import("@/views/Users/CreateEditView/index"),
    meta: {
      pageTitle: "Crear Usuario",
    },
  },
  {
    path: "/users/edit/:id/:typeUser",
    name: "user-edit",
    component: () => import("@/views/Users/CreateEditView/index"),
    meta: {
      pageTitle: "Editar usuario",
    },
  },
  {
    path: "/schools/create",
    name: "schools-create",
    component: () => import("@/views/Schools/CreateEditView/index.vue"),
    meta: {
      pageTitle: "Nuevo Colegio",
      breadcrumb: [
        {
          text: "Lista",
          to: "/schools",
        },
        {
          text: "Nuevo",
          active: true,
        },
      ],
    },
  },
  {
    path: "/schools/:id/edit",
    name: "schools-edit",
    component: () => import("@/views/Schools/CreateEditView/index.vue"),
    meta: {
      pageTitle: "Editar Colegio",
      breadcrumb: [
        {
          text: "Lista",
          to: "/schools",
        },
        {
          text: "Editar Colegio",
          active: true,
        },
      ],
    },
  },
  {
    path: "/schools/:id",
    name: "schools-view",
    component: () => import("@/views/Schools/CreateEditView/index.vue"),
    meta: {
      pageTitle: "Ver Colegio",
      breadcrumb: [
        {
          text: "Lista",
          to: "/schools",
        },
        {
          text: "Ver Colegio",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/create",
    name: "products-create",
    component: () => import("@/views/Products/CreateEditView/index.vue"),
    meta: {
      pageTitle: "Nuevo Producto",
      breadcrumb: [
        {
          text: "Lista",
          to: "/products",
        },
        {
          text: "Nuevo",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/:id/edit/:page?",
    name: "products-edit",
    component: () => import("@/views/Products/CreateEditView/index.vue"),
    meta: {
      pageTitle: "Editar Producto",
      breadcrumb: [
        {
          text: "Lista",
          to: "/products",
        },
        {
          text: "Editar Producto",
          active: true,
        },
      ],
    },
  },
  // *===============================================---*
  // *--------- ECOMMERCE  ---------------------------------------*
  // *===============================================---*
  {
    path: "/e-commerce/checkout",
    name: "apps-e-commerce-checkout",
    component: () =>
      import(
        "@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue"
      ),
    meta: {
      pageTitle: "Procesar compra",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Tienda",
          to: "/e-commerce/shop",
        },
        {
          text: "Caja",
          active: true,
        },
      ],
    },
  },
  {
    path: "/profile/:id/user",
    name: "profile-user",
    component: () => import("@/views/Users/ViewUser"),
    meta: {
      pageTitle: "Perfil",
      breadcrumb: [
        {
          text: "Lista",
          active: true,
        },
        {
          text: "Perfil",
          active: true,
        },
      ],
    },
  },
  {
    path: "/invoice/preview/:id",
    name: "apps-invoice-preview",
    component: () =>
      import("@/views/apps/invoice/invoice-preview/InvoicePreview.vue"),
    meta: {
      pageTitle: "Factura",
      breadcrumb: [
        {
          text: "Vista Previa",
          active: true,
        },
      ],
    },
  },
  {
    path: "/invoice/ticket/:id",
    name: "apps-invoice-ticket",
    component: () =>
      import("@/views/apps/invoice/invoice-preview/InvoiceTicket.vue"),
    meta: {
      pageTitle: "Ticket",
      breadcrumb: [
        {
          text: "Vista Previa",
          active: true,
        },
      ],
    },
  },
  {
    path: "/menu",
    name: "menu",
    component: () => import("@/views/Menu/index.vue"),
    meta: {
      pageTitle: "Menu",
    },
  },
  {
    path: "/create/menu",
    name: "create-menu",
    component: () => import("@/views/Menu/CreateEditView/index.vue"),
    meta: {
      pageTitle: "Crear Menu",
    },
  },
  {
    path: "/banks",
    name: "banks",
    component: () => import("@/views/Bank/index.vue"),
    meta: {
      pageTitle: "Bancos",
    },
  },
  {
    path: "/bank-create",
    name: "bank-create",
    component: () => import("@/views/Bank/CreateEditView/index.vue"),
    meta: {
      pageTitle: "Datos Bancarios",
    },
  },
  {
    path: "/bank-edit/:id",
    name: "bank-edit",
    component: () => import("@/views/Bank/CreateEditView/index.vue"),
    meta: {
      pageTitle: "Editar Datos Bancarios",
    },
  },
  {
    path: "/notifications",
    name: "notifications",
    component: () => import("@/views/Notification/index.vue"),
    meta: {
      pageTitle: "Notificaciones",
    },
  },
  {
    path: "/notifications/create",
    name: "notification-create",
    component: () => import("@/views/Notification/CreateEditView/index.vue"),
    meta: {
      pageTitle: "Crear Notificación",
    },
  },
  {
    path: "/notifications/edit/:id",
    name: "notification-edit",
    component: () => import("@/views/Notification/CreateEditView/index.vue"),
    meta: {
      pageTitle: "Editar Notificación",
    },
  },
  {
    path: "/Bot-WhatsApp",
    name: "bot-WhatsApp",
    component: () => import("@/views/BotWhatsApp/index.vue"),
    meta: {
      pageTitle: "Bot whatsapp",
    },
  },
];
