import axios from '@axios'

export default {
    index({ commit }, school_id) {
        return new Promise((resolve, reject) => {
        axios.get(`/api/menu/` + school_id)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    store({ commit }, payload) {
        return new Promise((resolve, reject) => {
        axios.post('/api/menu/store', payload)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
}