import router from "@/router";

export default {
    namespaced: true,
    state: {
        pageTitle: router.currentRoute?.meta?.pageTitle
    },
    mutations: {
        setPageTitle(state, val) {
            state.pageTitle = val;
        },
    },
    getters: {
        getPageTitle: state => state.pageTitle,
    },
}
