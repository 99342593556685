import axios from '@axios'

export default {
    index({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.get('/api/roles', payload)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        }
        )
    },
    syncRoles({ commit }, role) {
        return new Promise((resolve, reject) => {
            axios.post(`/api/roles`, role)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    unsyncRoles({ commit }, role) {
        return new Promise((resolve, reject) => {
            axios.put(`/api/roles/${role.id}`, role)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}