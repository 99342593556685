import axios from "@axios";

export default {
  index({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/products`, { params })
        .then((res) => {
          commit("SET_PRODUCTS", res.data.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  show({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/products/` + id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  store({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/products", payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("/api/products/" + payload.id, payload.data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  delete({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/api/products/" + payload.id, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Categories actions
  indexCategory({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/categories", { params })
        .then((res) => {
          commit("SET_CATEGORIES", res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  storeCategory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/categories", payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateCategory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put("/api/categories/" + payload.id, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteCategory({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/api/categories/" + id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getPayments({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/payment-types", { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  pay({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/products/pay", payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  payPOS({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/products/pay-pos", payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
