import axios from '@axios'

export default {
    index({ commit }, params) {
        return new Promise((resolve, reject) => {
            axios.get('/api/schools', { params })
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    store({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/api/schools', payload)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    update({ commit }, {id, data}) {
        return new Promise((resolve, reject) => {
            axios.put('/api/schools/' + id, data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    delete({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios.delete('/api/schools/' + id)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios.get('/api/schools/' + id)
            .then(res => {
                commit('SET_SCHOOL', res.data)
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    }
}