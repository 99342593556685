export default [
  {
    path: "/",
    name: "dashboard",
    component: () => import("@/views/Dashboard/ecommerce/Ecommerce.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users",
    name: "users",
    component: () => import("@/views/Users"),
    meta: {
      pageTitle: "Usuarios",
      breadcrumb: [
        {
          text: "Lista de Usuarios",
          active: true,
        },
      ],
    },
  },
  {
    path: "/profile/:id/user",
    name: "profile-user",
    component: () => import("@/views/Users/ViewUser"),
    meta: {
      pageTitle: "Perfil",
      breadcrumb: [
        {
          text: "Lista",
          active: true,
        },
      ],
    },
  },
  {
    path: "/users/users-students",
    name: "users-students",
    component: () => import("@/views/Users"),
    meta: {
      pageTitle: "Estudiantes",
      breadcrumb: [
        {
          text: "Lista de Estudiantes",
          active: true,
        },
      ],
    },
  },
  {
    path: "/users/users-representatives",
    name: "users-representatives",
    component: () => import("@/views/Users"),
    meta: {
      pageTitle: "Representantes",
      breadcrumb: [
        {
          text: "Lista de representantes",
          active: true,
        },
      ],
    },
  },
  {
    path: "/users/users-teachers",
    name: "users-teachers",
    component: () => import("@/views/Users"),
    meta: {
      pageTitle: "Profesores",
      breadcrumb: [
        {
          text: "Lista de profesores",
          active: true,
        },
      ],
    },
  },
  {
    path: "/schools",
    name: "schools",
    component: () => import("@/views/Schools"),
    meta: {
      pageTitle: "Colegios",
      breadcrumb: [
        {
          text: "Lista",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products",
    name: "products",
    component: () => import("@/views/Products"),
    meta: {
      pageTitle: "Productos",
      breadcrumb: [
        {
          text: "Lista",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/create",
    name: "products-create",
    component: () => import("@/views/Products/CreateEditView/index.vue"),
    meta: {
      pageTitle: "Nuevo Producto",
      breadcrumb: [
        {
          text: "Lista",
          to: "/products",
        },
        {
          text: "Nuevo",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/:id/edit",
    name: "products-edit",
    component: () => import("@/views/Products/CreateEditView/index.vue"),
    meta: {
      pageTitle: "Editar Producto",
      breadcrumb: [
        {
          text: "Lista",
          to: "/products",
        },
        {
          text: "Editar Producto",
          active: true,
        },
      ],
    },
  },
  {
    path: "/products/categories",
    name: "products-categories",
    component: () => import("@/views/Products/Categories"),
    meta: {
      pageTitle: "Categorias",
      breadcrumb: [
        {
          text: "Lista",
          active: true,
        },
      ],
    },
  },
  {
    path: "/transactions/:userId?",
    name: "transactions",
    component: () => import("@/views/Transactions/index"),
    meta: {
      pageTitle: "Transacciónes",
      breadcrumb: [
        {
          text: "Lista",
          active: true,
        },
      ],
    },
  },
  // *===============================================---*
  // *--------- ECOMMERCE  ---------------------------------------*
  // *===============================================---*
  {
    path: "/e-commerce/checkout",
    name: "apps-e-commerce-checkout",
    component: () =>
      import(
        "@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue"
      ),
    meta: {
      pageTitle: "Procesar compra",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Tienda",
          to: "/",
        },
        {
          text: "Caja",
          active: true,
        },
      ],
    },
  },
  {
    path: "/invoice/preview/:id",
    name: "apps-invoice-preview",
    component: () =>
      import("@/views/apps/invoice/invoice-preview/InvoicePreview.vue"),
    meta: {
      pageTitle: "Factura",
      breadcrumb: [
        {
          text: "Vista Previa",
          active: true,
        },
      ],
    },
  },
  {
    path: "/e-commerce/shop",
    name: "apps-e-commerce-shop",
    component: () =>
      import(
        "@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue"
      ),
    meta: {
      pageTitle: "Procesar compra",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Tienda",
          to: "/",
        },
        {
          text: "Caja",
          active: true,
        },
      ],
    },
  },

  {
    path: "/invoice/ticket/:id",
    name: "apps-invoice-ticket",
    component: () =>
      import("@/views/apps/invoice/invoice-preview/InvoiceTicket.vue"),
    meta: {
      pageTitle: "Ticket",
      breadcrumb: [
        {
          text: "Vista Previa",
          active: true,
        },
      ],
    },
  },
  {
    path: "/menu",
    name: "menu",
    component: () => import("@/views/Menu/index.vue"),
    meta: {
      pageTitle: "Menu",
    },
  },
  {
    path: "/update/menu",
    name: "create-menu",
    component: () => import("@/views/Menu/CreateEditView/index.vue"),
    meta: {
      pageTitle: "Crear Menu",
    },
  },
  {
    path: "/users/create/:typeUser",
    name: "user-create",
    component: () => import("@/views/Users/CreateEditView/index"),
    meta: {
      pageTitle: "Crear Usuario",
    },
  },
  {
    path: "/users/edit/:id/:typeUser",
    name: "user-edit",
    component: () => import("@/views/Users/CreateEditView/index"),
    meta: {
      pageTitle: "Editar usuario",
    },
  },
];
