import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
    namespaced: true,
    state: {
        showUser: {},
        token: null,
        users: [],
        roles: [],
        permissions: [],
    },
    mutations,
    actions,
    getters
}
