import axios from '@axios'

export default {
    index({ commit }, params) {
        return new Promise((resolve, reject) => {
            axios.get('/api/transactions', {params})
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    approve({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/api/transaction-approve/${payload.id}`, payload.params)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    reject({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post(`/api/transaction-reject/${payload.transactionId}`, payload.data, { params: payload.params })
              .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
}