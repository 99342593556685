import axios from '@axios'

export default {
    index({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.get('/api/notifications', payload)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    show({ commit }, notificationId) {
        return new Promise((resolve, reject) => {
            axios.get('/api/notifications/' + notificationId)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    store({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/api/notifications', payload)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    update({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.put('/api/notifications/' + payload.id, payload.data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    delete({ commit }, notificationId) {
        return new Promise((resolve, reject) => {
            axios.delete('/api/notifications/' + notificationId)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    close({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/api/notifications/close/' + payload.notificationId, payload.params)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    fetchNotifacationItems({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.get('/api/cashier-notifications', payload)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    fetchNotifacationItemsClose ({ commit }, notificationId) {
        return new Promise((resolve, reject) => {
            axios.post('/api/cashier-notifications/close/'+notificationId)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    }
}