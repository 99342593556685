import { getUserData } from '@/auth/utils'
export default {
    userData() {
        return getUserData()
    },
    accessToken() {
        return localStorage.getItem('accessToken')
    },
    roles(state) {
        return state.roles
    },
}