import axios from "@axios";

export default {
  getPayments({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/pos/payment-types", { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  indexCategory({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/pos/categories", { params })
        .then((res) => {
          commit("SET_CATEGORIES", res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  indexSchool({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/pos/schools", { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  index({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/pos/products`, { params })
        .then((res) => {
          commit("SET_PRODUCTS", res.data.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getUser({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/pos/search-user/${params.search}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  preparePOS({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/pos/prepare`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  payPOS({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/pos/pay-POS`, payload.data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },
  payFailed({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/pos/pay-failed`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  payCancel({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/pos/pay-cancel`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
