import axios from '@axios';

export default {
  fSearchUser({ commit }, search) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/search/e-commerce/shop', { search })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
