import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import users from './users'
import schools from './schools'
import products from './products'
import transactions from './transactions'
import roles from './roles'
import menu from './menu'
import bank from './bank'
import notifications from './notifications'
import pos from './pos'
import search from './search'
import EcommerceData from './ecommerce'
import ecommerceStoreModule from '../views/apps/e-commerce/eCommerceStoreModule'
import invoiceStoreModule from '../views/apps/invoice/invoiceStoreModule'
import metaPage from './metaPage'
import botWhatsApp from './bot-whatsApp'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    schools,
    products,
    transactions,
    roles,
    EcommerceData,
    menu,
    bank,
    notifications,
    pos,
    'user': users,
    'app-ecommerce': ecommerceStoreModule,
    'app-invoice': invoiceStoreModule,
    'search': search,
    metaPage,
    botWhatsApp
  },
  strict: process.env.DEV,
})
