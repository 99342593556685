import axios from '@axios'

export default {
    index({ commit }, params) {
        return new Promise((resolve, reject) => {
        axios.get('/api/banks', params)
            .then(res => {
                if(params) {
                    commit('SET_RATE', res.data)
                }
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    show({ commit }, id) {
        return new Promise((resolve, reject) => {
        axios.get('/api/banks/' + id)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    store({ commit }, payload) {
        return new Promise((resolve, reject) => {
        axios.post('/api/banks', payload)
            .then(res => {
                resolve(res.data)
                if(res.data.rate) {
                    commit('SET_RATE', res.data.rate)
                }
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    storeRate({ commit }, payload) {
        return new Promise((resolve, reject) => {
        axios.post('/api/rate', payload)
            .then(res => {
                resolve(res.data)
                if(res.data.commit) {
                    commit('SET_RATE', res.data.response)
                }
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    update({ commit }, payload) {
        return new Promise((resolve, reject) => {
        axios.put('/api/banks/' + payload.id, payload.formData)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
}