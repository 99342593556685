import axios from '@axios'

export default {
  namespaced: true,
  state: {
    invoices: [],
  },
  getters: {
    invoicesData(state) {
      return state.invoices
    },
  },
  mutations: {
    SET_INVOICES_DATA(state, invoices) {
      state.invoices = invoices
    },
  },
  actions: {
    fetchInvoices({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/apps/invoices`)
        .then(response => {
          commit('SET_INVOICES_DATA', response.data)
          resolve(response)
        })
        .catch(err => {
            reject(err)
        })
      })
    },
    fetchInvoice(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/apps/invoices/${payload.id}`, {params: payload.params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    printTicket(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/apps/invoice/print/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    fetchInvoiceTicket(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/invoice/ticket/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
