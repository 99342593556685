export default [
  {
    path: "/",
    name: "dashboard",
    component: () =>
      import("@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue"),
    meta: {
      contentRenderer: "sidebar-left-detached",
      contentClass: "ecommerce-application",
      requiresAuth: true,
    },
  },
  {
    path: "/schools",
    name: "schools",
    component: () => import("@/views/Schools"),
    meta: {
      pageTitle: "Colegios",
      breadcrumb: [
        {
          text: "Lista",
          active: true,
        },
      ],
    },
  },
  {
    path: "/transactions",
    name: "transactions",
    component: () => import("@/views/Transactions/index"),
    meta: {
      pageTitle: "Transacciónes",
      breadcrumb: [
        {
          text: "Lista",
          active: true,
        },
      ],
    },
  },
  {
    path: "/users/edit/:id",
    name: "user-edit",
    component: () => import("@/views/Users/CreateEditView/index"),
    meta: {
      pageTitle: "Editar usuario",
    },
  },
  // *===============================================---*
  // *--------- ECOMMERCE  ---------------------------------------*
  // *===============================================---*
  {
    path: "/e-commerce/checkout",
    name: "apps-e-commerce-checkout",
    component: () =>
      import(
        "@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue"
      ),
    meta: {
      pageTitle: "Procesar compra",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Tienda",
          to: "/",
        },
        {
          text: "Caja",
          active: true,
        },
      ],
    },
  },
  {
    path: "/invoice/preview/:id",
    name: "apps-invoice-preview",
    component: () =>
      import("@/views/apps/invoice/invoice-preview/InvoicePreview.vue"),
    meta: {
      pageTitle: "Factura",
      breadcrumb: [
        {
          text: "Vista Previa",
          active: true,
        },
      ],
    },
  },
  {
    path: "/profile/:id/user",
    name: "profile-user",
    component: () => import("@/views/Users/ViewUser"),
    meta: {
      pageTitle: "Perfil",
      breadcrumb: [
        {
          text: "Lista",
          active: true,
        },
      ],
    },
  },
];
