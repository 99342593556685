import axios from '@axios'

export default {
    index({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.get('/api/apps/ecommerce/data', payload)
            .then(res => {
                commit('SET_ECOMMERCE_DATA', res.data)
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    reports({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.get(`/api/reports/${payload.endpoint}/${payload.type}`, payload, { params: payload.params })
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
}