export default {
    SET_USERS(state, users) {
        state.users = users
    },
    SET_USER(state, user) {
        state.showUser = user
    },
    SET_ROLES(state, roles) {
        state.roles = roles
    },
    SET_PERMISSIONS(state, permissions) {
        state.permissions = permissions
    },
    REMOVE_DATA_USER() {
        localStorage.removeItem('userData')
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
    },
    SET_INFO_USER(state, info) {
        localStorage.setItem('accessToken', info.accessToken)
        localStorage.setItem('userData', JSON.stringify(info.userData))
        localStorage.setItem('refreshToken', info.refreshToken)
    },
    SET_INFO_USER_VALID(state, userData) {
        localStorage.setItem('userData', JSON.stringify(userData))
    },
}