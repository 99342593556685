import axios from "axios";

export default {
    
  getConfigBot() {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_URL_API_BOT + "scan-qr")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  postConfigMessage(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_URL_API_BOT + "send-message", data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
